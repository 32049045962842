import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { lastValueFrom } from 'rxjs';
import { UrlService } from './url.service';
import { environment } from '../../environments/environment';
import {
  Achievement,
  AchievementsResponse,
} from '../interfaces/achievement.interface';
import {
  Referral,
  ReferralInformation,
  ReferralsResponse,
} from '../interfaces/referral.interface';

@Injectable({ providedIn: 'root' })
export class AchievementsService extends UrlService {
  constructor(private readonly _http: HttpClient) {
    super();
  }

  public async loginAchievement(): Promise<any> {
    const url = this.getUrlGen1('achievements-triggerByRequestName');
    const data = {
      appId: environment.projectId,
      requestName: 'login_to_cointlist',
      progress: 1,
    };
    const response = await lastValueFrom(this._http.post<any>(url, data));
    return response;
  }

  public async getReferralAchivements(
    achievementsIds: string[]
  ): Promise<Achievement[]> {
    const url = this.getUrlGen1('achievements-getByIds');
    const data = {
      appId: environment.projectId,
      ids: achievementsIds,
    };
    const response = await lastValueFrom(
      this._http.post<AchievementsResponse>(url, data)
    );
    return response.achievements;
  }

  public async getReferralsAmount(): Promise<Referral[]> {
    const url = this.getUrlGen1('referrals-getUserReferrals');
    const data = {
      appId: environment.projectId,
    };
    const response = await lastValueFrom(
      this._http.post<ReferralsResponse>(url, data)
    );
    return response.referrals;
  }

  public async getReferralsInformation(): Promise<ReferralInformation> {
    const url = this.getUrlGen1('referrals-getReferralSettingsByAppId');
    const data = {
      appId: environment.projectId,
    };
    const response = await lastValueFrom(
      this._http.post<ReferralInformation>(url, data)
    );
    return response;
  }

  public async referralInvite(email: string): Promise<any> {
    const url = this.getUrlGen1('referrals-invite');
    const data = {
      appId: environment.projectId,
      emails: [email],
    };
    const response = await lastValueFrom(this._http.post<any>(url, data));
    return response;
  }
}
