import { createAction, props } from '@ngrx/store';
import { UserEntry } from '../../interfaces/entry.interface';
import { Inventory } from '../../interfaces/inventory.interface';

// Leaderboard
export const loadLeaderboard = createAction(
  '[Universe] Load Leaderboard',
  props<{ leaderboardName: string }>()
);

export const loadLeaderboardSuccess = createAction(
  '[Universe] Load Leaderboard Success',
  props<{ data: any }>()
);

export const loadLeaderboardFailure = createAction(
  '[Universe] Load Leaderboard Failure',
  props<{ error: any }>()
);

// User Entry
export const getUserEntry = createAction(
  '[Universe] Get User Entry',
  props<{ leaderboardId: string }>()
);

export const getUserEntrysSuccess = createAction(
  '[Universe] Get User Entry Success',
  props<{ data: UserEntry }>()
);

export const getUserEntryFailure = createAction(
  '[Universe] Get User Entry Failure',
  props<{ error: any }>()
);

// Virtual Items
export const getVirtualItems = createAction(
  '[Universe] Get Virtual Items',
  props<{ tag: string }>()
);

export const getVirtualItemsSuccess = createAction(
  '[Universe] Get Virtual Items Success',
  props<{ data: any }>()
);

export const getVirtualItemsFailure = createAction(
  '[Universe] Get Virtual Items Failure',
  props<{ error: any }>()
);

// Inventory
export const getInventory = createAction('[Universe] Get Inventory');

export const getInventorySuccess = createAction(
  '[Universe] Get Inventory Success',
  props<{ data: Inventory }>()
);

export const getInventoryFailure = createAction(
  '[Universe] Get Inventory Failure',
  props<{ error: any }>()
);

// Claim Badge
export const claimBadge = createAction(
  '[Universe] Claim Badge',
  props<{ virtualItemId: string }>()
);

export const claimBadgeSuccess = createAction('[Universe] Claim Badge Success');

export const claimBadgeFailure = createAction(
  '[Universe] Claim Badge Failure',
  props<{ error: any }>()
);

// Login Achievement
export const loginAchievement = createAction('[Universe] Login Achievement');
export const loginAchievementSuccess = createAction(
  '[Universe] Login Achievement Success'
);
export const loginAchievementFailure = createAction(
  '[Universe] Login Achievement Failure',
  props<{ error: any }>()
);

export const UniverseActions = {
  loadLeaderboard,
  loadLeaderboardSuccess,
  loadLeaderboardFailure,
  getUserEntry,
  getUserEntrysSuccess,
  getUserEntryFailure,
  getVirtualItems,
  getVirtualItemsSuccess,
  getVirtualItemsFailure,
  getInventory,
  getInventorySuccess,
  getInventoryFailure,
  claimBadge,
  claimBadgeSuccess,
  claimBadgeFailure,
  loginAchievement,
  loginAchievementSuccess,
  loginAchievementFailure,
};
