import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UrlService } from './url.service';
import { environment } from '../../environments/environment';
import { lastValueFrom } from 'rxjs';
import { VirtualItem } from '../interfaces/virtual-item.interface';
import { Inventory } from '../interfaces/inventory.interface';

@Injectable({ providedIn: 'root' })
export class NftService extends UrlService {
  constructor(private readonly _http: HttpClient) {
    super();
  }

  public async claimNft(itemIds: string[]) {
    const url = this.getUrlGen1('storeV2-buyVirtualItems');
    const data = {
      appId: environment.projectId,
      itemIds: itemIds,
    };
    const response = await lastValueFrom(this._http.post<any>(url, data));
    return response;
  }

  public async getNfts(): Promise<VirtualItem[]> {
    const url = this.getUrlGen1('inventoryV2-getByAppIds');
    const data = {
      appIds: [environment.projectId],
    };
    const response = await lastValueFrom(
      this._http.post<VirtualItem[]>(url, data)
    );
    return response;
  }

  public async getInventory(): Promise<Inventory> {
    const url = this.getUrlGen1('inventoryV2-getWithVirtualItemsDataByAppIds');
    const data = {
      appIds: [environment.projectId],
    };
    const response = await lastValueFrom(this._http.post<Inventory>(url, data));
    return response;
  }
}
