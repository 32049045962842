import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, firstValueFrom } from 'rxjs';

import { AuthService } from './auth.service';
import { UrlService } from './url.service';
import { WalletV3Ext, WalletsResponseV3 } from '../interfaces/wallet.interface';
import { IAuthNewUserData } from '../interfaces/auth.interface';

@Injectable({ providedIn: 'root' })
export class WalletService extends UrlService {
  private user: IAuthNewUserData | null = null;
  private readonly wallet = new BehaviorSubject<WalletV3Ext | null>(null);
  readonly wallet$ = this.wallet.asObservable();

  constructor(
    private readonly _authService: AuthService,
    private readonly _http: HttpClient
  ) {
    super();

    this._authService.user$.subscribe((data) => {
      this.user = data;

      if (!data) {
        this.clearWallet();
      }
    });
  }

  /* not used now
    private async setRgnWallet(walletAddress: string): Promise<boolean> {
        const url = this.getUrlGen1('wallets-setPrimaryRgnWallet');

        return firstValueFrom(
            this._http.post(url, { walletAddress, version: 2 }).pipe(
                map(() => true),
                catchError(() => of(false)),
            )
        );
    }

    public getWallet(): Observable<WalletV3Ext> {
        return this.wallet$;
    }

    public getWalletNow(): WalletV3Ext {
        return this.wallet.value;
    }
    */

  public clearWallet(): void {
    this.wallet.next(null);
  }

  public async getRgnWallet(): Promise<boolean> {
    try {
      const data = await firstValueFrom(
        this._http.post<WalletsResponseV3>(
          this.getUrlGen1('wallets-getUserWallets'),
          {
            data: { version: 3 },
          }
        )
      );

      const wallets = Object.entries(data.result.hash).map(
        (data): WalletV3Ext => ({
          wallets: data[1],
          id: `0x${data[0]}`,
        })
      );

      if (wallets.length === 0) return false;

      this.wallet.next(wallets[0]);

      return true;
    } catch (err) {
      return false;
    }
  }

  /*
    public async createRgnWallet(password: string) {
        const url = this.getUrlGen1('wallets-createWallet');
        const response = await firstValueFrom<any>(this._http.post(url, { version: 2, password }));
        return response;
    }

    public connectWallet(): Observable<any> {
        const dialogRef = this._dialog.open(WalletConnectDialogComponent, {
            data: null,
            maxWidth: '600px',
            panelClass: '',
            width: '90%',
        });

        return dialogRef.afterClosed();
    }
    */
}
